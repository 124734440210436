<template>
  <a-spin :spinning="false">
    <div style="height: 24px"></div>
    <div class="questions-count-box">
      <div class="questions-count-header">
        <div class="my-result" @click="gotodetail">
          {{ $t("quesition.my_result") }}
        </div>
        <h1>{{ pageName || dataInfo.questionnaireName }}</h1>
        <p>
          {{ dataInfo.intro }}
        </p>
      </div>

      <div class="content">
        <div class="questions-count-content">
          <template v-for="(item, itemIndex) in quesData" :key="itemIndex">
            <div class="qccr-title">
              <span class="qccr-title-label">
                [{{ getQuestionType(item.questionType) }}]
              </span>
              <span class="qccr-title-value">
                {{ itemIndex + 1 }}、{{ item.title }}
              </span>
              <a-button
                type="link"
                size="small"
                @click="showMedia(mv)"
                v-for="(mv, mi) in item.titimgs"
                :key="'media' + mi"
                >[{{ $t(getResourceTypeStr(mv)) }}]</a-button
              >
            </div>

            <div
              class="questions-count-content-row"
              v-if="[1, 2, 9, 10].includes(item.questionType)"
            >
              <a-table
                :dataSource="item.scoreList"
                :columns="allColumns[itemIndex]"
                className="questions-count-content-table"
                :rowKey="(record, index) => index"
                bordered
                :pagination="false"
              >
                <template #title_option>{{ $t("LB_Exam_Option") }}</template>
                <template #title_vote>{{ $t("LB_Exam_TheVote") }}</template>
                <template #title_ratio>{{ $t("LB_Exam_TheRatio") }}</template>
                <template #theOption="{ record }">
                  {{ record.option }}
                  <a-button
                    type="link"
                    size="small"
                    @click="showMedia(record.media)"
                    v-if="record.media"
                    >[{{ $t(getResourceTypeStr(record.media)) }}]</a-button
                  >
                </template>
                <template #theVote="{ index }">
                  {{ item.scoreRange[index] }}
                </template>
                <template #theRatio="{ index }">
                  {{ statisticsResults(item.scoreRange[index]) }}%
                </template>
              </a-table>
            </div>
            <!-- 区块2 -->
            <div
              class="questions-count-content-row"
              v-if="item.questionType == 5"
            >
              <div
                class="easy-answer-row"
                v-for="(aitem, aindex) in item.analysis"
                :key="aindex"
              >
                {{ aindex + 1 }}、{{ aitem.realName }}({{ aitem.account }})：{{
                  aitem.answer
                }}
              </div>
            </div>
            <div
              class="questions-count-content-row"
              v-if="item.questionType == 6 || item.questionType == 7"
            >
              <a-table
                :dataSource="[{ questionType: item.questionType }]"
                :columns="allColumns[itemIndex]"
                className="questions-count-content-table"
                :rowKey="(record, index) => index"
                bordered
                :pagination="false"
              >
                <template #title_AverageScore>{{
                  $t("homework.AverageScore")
                }}</template>
              </a-table>
            </div>
            <!-- 区块3 -->
            <div
              class="questions-count-content-row"
              v-if="item.questionType == 11"
            >
              <a-table
                :dataSource="item.scoreData"
                :columns="allColumns[itemIndex]"
                className="questions-count-content-table"
                :rowKey="(record, index) => index"
                bordered
                :pagination="false"
              >
                <template #title_option>{{ $t("LB_Exam_Option") }}</template>
                <template #title_AverageScore>{{
                  $t("homework.AverageScore")
                }}</template>
                <template #theOption="{ record }">
                  {{ record.option }}
                  <a-button
                    type="link"
                    size="small"
                    @click="showMedia(record.media)"
                    v-if="record.media"
                    >[{{ $t(getResourceTypeStr(record.media)) }}]</a-button
                  >
                </template>
              </a-table>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div style="height: 24px"></div>
    <a-modal
      v-model:visible="mediaModal"
      :title="mediaTitle"
      :width="900"
      :footer="null"
    >
      <div style="text-align: center">
        <div v-if="mediaImage != ''">
          <a-image style="max-width: 800px" :src="mediaImage" />
        </div>
        <div v-if="mediaAudio != ''">
          <!-- 显示音频录音 -->
          <audio controls style="width: 800px">
            <source :src="mediaAudio" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
        <div v-if="mediaVideo != ''">
          <!-- 显示视频录制 -->
          <video width="800" controls>
            <source :src="mediaVideo" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { ref, reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { trackQuestionnaireAnalysis } from "@/api/train.js";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const state = reactive({
      mediaModal: false,
      mediaImage: "",
      mediaVideo: "",
      mediaAudio: "",
      mediaTitle: "",
    });
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const spinning = ref(false);
    const dataInfo = ref({});
    const quesData = ref([]);
    const allColumns = reactive({});
    const taskId = route.query.taskId;
    const detailId = route.query.detailId;
    const questionnaireId = route.query.questionnaireId;
    const pageName = route.query.name;

    const userCount = ref(0);
    const gotodetail = () => {
      router.back();
    };

    const getSliderMarks = (que) => {
      if (que.questionType == 7) {
        let score = que.settingObj.score;
        let startValue = que.settingObj.startValue;
        let arr = {};
        for (let i = 1; i <= (score == 100 ? 10 : score); i++) {
          if (score == 100) {
            arr[i] = startValue + i * 10 - 10;
          } else {
            arr[i] = startValue + i - 1;
          }
        }
        return arr;
      } else {
        let score = que.settingObj.score;
        let arr = {};
        for (let i = 1; i <= score; i++) {
          if (score == 100) {
            if (i % 10 == 0) arr[i] = i;
          } else {
            arr[i] = i;
          }
        }
        return arr;
      }
    };

    const getTypeList = () => {
      return [
        "",
        "LB_Exam_Radio",
        "LB_Exam_MultiSelect",
        "",
        "",
        "CM_QA",
        "LB_Exam_RatingQuestion",
        "LB_Exam_ScaleQuestions",
        "",
        "LB_Exam_PictureSingleChoice",
        "LB_Exam_PictureMultipleChoice",
        "LB_Exam_CombinationEvaluationQuestion",
      ];
    };

    const getTypeOne = (type) => {
      let typeList = getTypeList();
      return typeList[type];
    };
    const getQuestionType = (type) => {
      let str = t(getTypeOne(type));
      return str;
    };

    const CreatePiceBtn = (url) => {
      let text = t(getResourceTypeStr(url));
      return "";
    };

    const setAllColumns = (quesData) => {
      let typeList = getTypeList();
      let columns = [];
      quesData.value.forEach((info, info_index) => {
        var typename = typeList[info.questionType];
        if (
          typename == "LB_Exam_Radio" ||
          typename == "LB_Exam_MultiSelect" ||
          typename == "LB_Exam_PictureSingleChoice" ||
          typename == "LB_Exam_PictureMultipleChoice"
        ) {
          columns = [
            {
              dataIndex: "option",
              slots: { title: "title_option", customRender: "theOption" },
              i18nTitle: "LB_Exam_Option",
              width: 500,
              align: "left",
              fixed: false,
              sorter: false,
            },
            {
              dataIndex: "TheVote",
              i18nTitle: "page.TheVote",
              slots: { title: "title_vote", customRender: "theVote" },
              align: "center",
              width: 239,
              fixed: false,
              sorter: false,
            },
            {
              dataIndex: "TheRatio",
              i18nTitle: "page.TheRatio",
              slots: { title: "title_ratio", customRender: "theRatio" },
              align: "center",
              width: 239,
              fixed: false,
              sorter: false,
            },
          ];
          allColumns[info_index] = columns;
        } else if (
          typename == "LB_Exam_RatingQuestion" ||
          typename == "LB_Exam_ScaleQuestions"
        ) {
          let columns = [];
          let avgnum = 0;

          let scores = getSliderMarks(info);
          let numberArr = [];
          for (let index in scores) {
            let item = scores[index];
            numberArr.push(item);
          }
          if (
            info.settingObj.score == 100 &&
            typename == "LB_Exam_RatingQuestion"
          ) {
            avgnum =
              info.analysis.length == 0
                ? 0
                : info.scoresTotal / info.analysis.length;
          } else {
            numberArr.forEach((item, index) => {
              avgnum +=
                info.scoreRange[index] == 0 ? 0 : info.scoreRange[index] * item;
            });
            avgnum =
              info.analysis.length == 0 ? 0 : avgnum / info.analysis.length;
          }
          columns.unshift({
            dataIndex: "AverageScore",
            i18nTitle: "page.AverageScore",
            slots: { title: "title_AverageScore" },
            align: "center",
            fixed: false,
            sorter: false,
            customRender: ({ record, index }) => {
              return {
                children: avgnum.toFixed(2),
              };
            },
          });

          numberArr.forEach((item, index) => {
            let num = info.scoreRange[index];
            let rate =
              info.analysis.length == 0 ? 0 : num / info.analysis.length;
            rate = num + "(" + Math.round(rate * 100) + "%)";
            columns.unshift({
              title: item + "",
              align: "center",
              fixed: false,
              sorter: false,
              customRender: ({ record }) => {
                return {
                  children: rate,
                };
              },
            });
          });

          allColumns[info_index] = columns;
        } else if (typename == "LB_Exam_CombinationEvaluationQuestion") {
          let columns = [];
          let avgnum =
            info.analysis.length == 0
              ? "0.0"
              : (
                  info.avgnums.reduce((a, b) => a + b, 0) /
                  info.avgnums.length /
                  info.analysis.length
                ).toFixed(2);

          let scores = getSliderMarks(info);
          let numberArr = [];
          for (let index in scores) {
            let item = scores[index];
            numberArr.push(item);
          }
          columns.unshift({
            dataIndex: "AverageScore",
            i18nTitle: "page.AverageScore",
            slots: { title: "title_AverageScore" },
            align: "center",
            fixed: false,
            sorter: false,
            children: [
              {
                align: "center",
                colSpan: 0,
                customRender: ({ record, index }) => {
                  return info.analysis.length == 0
                    ? "0.0"
                    : (info.avgnums[index] / info.analysis.length).toFixed(2);
                },
              },
              {
                colSpan: 0,
                align: "center",
                customRender: ({ record, index }) => {
                  return {
                    children: avgnum,
                    props: {
                      rowSpan: index == 0 ? info.scoreList.length : 0,
                    },
                  };
                },
              },
            ],
          });

          numberArr.forEach((item, itemI) => {
            columns.unshift({
              title: item + "",
              align: "center",
              fixed: false,
              sorter: false,
              customRender: ({ record, index }) => {
                return (
                  info.scoreData[index].scores[itemI].score +
                  info.scoreData[index].scores[itemI].rate
                );
              },
            });
          });

          columns.unshift({
            dataIndex: "option",
            slots: { title: "title_option", customRender: "theOption" },
            i18nTitle: "LB_Exam_Option",
            width: 180,
            align: "left",
            fixed: false,
            sorter: false,
          });
          allColumns[info_index] = columns;
        }
      });
    };

    const openModal = () => {
      spinning.value = false;
      dataInfo.value = [];
      quesData.value = [];
      const paermes = {
        taskId,
        detailId,
        questionnaireId,
      };
      trackQuestionnaireAnalysis(paermes).then((res) => {
        dataInfo.value = res.data;
        userCount.value = res.data.users;
        quesData.value = res.data.questions || [];

        quesData.value.map((item) => {
          item.settingObj = JSON.parse(item.setting);
          item.titimgs = item.titimgs || [];
          item.scoreList = [];
          item.options = item.options || [];
          item.analysis = item.analysis || [];
          item.options.forEach((opt, i) => {
            item.scoreList.push({
              option: opt,
              score: 0,
              remark: item.remarks[i],
              media: item.opimgs[i],
              label: item.labels[i],
            });
          });
          // 单选多选没有追评数据过滤
          if (item.questionType == 1 || item.questionType == 2) {
            item.analysisA = item.analysis.filter(
              (a) => a[`ps${item.options.length}`]
            );
          }
          if (item.questionType != 11) {
            // 计算评分题和量表题选项百分比
            item.scoreRange = new Array(item.scores.length).fill(0);
            item.tempScores = item.scores.map((s, sI) => {
              return {
                score: s,
                i: sI + 1,
              };
            });
            item.scoresTotal = 0;
            item.analysis.forEach((a) => {
              item.scores.map((s, sI) => {
                item.scoresTotal += a[`op${sI + 1}`];
                a[`op${sI + 1}`] > 0 && item.scoreRange[sI]++;
              });
            });
          } else {
            item.scoreValues = [];
            item.scoreData = [];
            item.avgnums = [];
            item.options.forEach((o, oI) => {
              item.scoreValues.push(
                new Array(
                  item.settingObj.score == 100 ? 10 : item.settingObj.score
                ).fill(0)
              );
              item.scoreData.push({
                option: o,
                media: item.opimgs[oI],
                scores: new Array(
                  item.settingObj.score == 100 ? 10 : item.settingObj.score
                ).fill({
                  score: 0,
                  rate: "(0%)",
                }),
              });
              item.avgnums.push(0);
            });
            item.analysis.forEach((a) => {
              item.options.forEach((o, oI) => {
                let op = a[`op${oI + 1}`];
                item.avgnums[oI] += op;
                item.scoreValues[oI][
                  (item.settingObj.score == 100 ? Math.ceil(op / 10) : op) - 1
                ]++;
              });
            });
            item.scoreValues.forEach((r1, r1I) => {
              r1.forEach((r2, r2I) => {
                if (r2) {
                  item.scoreData[r1I].scores[r2I] = {
                    score: r2,
                    rate:
                      "(" +
                      Math.round((r2 / item.analysis.length) * 100) +
                      "%)",
                  };
                }
              });
            });
          }
        });
        setAllColumns(quesData);
        spinning.value = false;
      });
    };

    const getResourceType = (url) => {
      if (!url || url == "") {
        return "";
      }
      const imgFormat = ["jpg", "png", "gif"];
      const videoFormat = ["mp4"];
      const audioFormat = ["mp3"];
      let u = url.split(".");
      let suffix = u[u.length - 1].toLowerCase();
      let type = "";
      if (imgFormat.indexOf(suffix) >= 0) {
        type = "image";
      } else if (videoFormat.indexOf(suffix) >= 0) {
        type = "video";
      } else if (audioFormat.indexOf(suffix) >= 0) {
        type = "audio";
      }
      return type;
    };

    const getResourceTypeStr = (url) => {
      let type = getResourceType(url);
      let arr = {
        image: "LB_Picture",
        video: "Pub_Video",
        audio: "LB_AudioFrequency",
      };
      return arr[type];
    };

    const statisticsResults = (analysis) => {
      return analysis > 0 ? ((analysis / userCount.value) * 100).toFixed(0) : 0;
    };

    const showMedia = (url) => {
      let type = getResourceType(url);
      state.mediaImage = "";
      state.mediaVideo = "";
      state.mediaAudio = "";
      if (type == "video") {
        state.mediaVideo = url;
      } else if (type == "audio") {
        state.mediaAudio = url;
      } else {
        state.mediaImage = url;
      }
      state.mediaTitle = t(getResourceTypeStr(url));
      state.mediaModal = true;
    };

    openModal();
    return {
      ...toRefs(state),
      userCount,
      pageName,
      gotodetail,
      taskId,
      detailId,
      questionnaireId,
      spinning,
      dataInfo,
      quesData,
      getResourceType,
      openModal,
      statisticsResults,
      allColumns,
      getQuestionType,
      getResourceTypeStr,
      showMedia,
    };
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common.less";
.questions-count-box {
  width: 1000px;
  min-height: 220px;
  margin: 0 auto;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #f5f6f7;
  padding: 46px 0 36px;
  position: relative;
  .my-result {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 118px;
    height: 34px;
    border: 1px solid var(--theme);
    font-size: 16px;
    color: var(--theme);
    text-align: center;
    line-height: 34px;
    cursor: pointer;
  }
  .questions-count-content {
    width: 98%;
    margin: 0 auto;
  }
  .questions-count-header {
    margin: 0 0 24px 0;
    h1 {
      font-size: 28px;
      line-height: 1.4;
      margin-bottom: 10px;
      color: #333;
      text-align: center;
    }
    p {
      font-size: 16px;
      line-height: 1.4;
      margin: 0 36px 0;
      color: #666;
      text-align: left;
    }
  }
  .questions-count-header-row {
    font-size: 12px;
    margin: 4px 0;
  }
  .questions-count-content-row {
    margin-bottom: 10px;
  }
  .qccr-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    word-break: break-all;
  }
  .qccr-title-label {
    margin-right: 4px;
  }
  ::v-deep .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px;
    overflow-wrap: break-word;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }
  ::v-deep .ant-table-tbody .ant-table-row td {
    padding: 8px;
    font-size: 14px;
    line-height: 24px;
    word-break: break-all;
  }
  .easy-answer-row {
    font-size: 14px;
    margin: 4px 0;
    line-height: 22px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
